import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import Login from 'src/pages/Login/Login';
import Test from 'src/pages/Test';
import MainRoutes from './MainRoutes';
import NoMatch from './NoMatchRoutes';

import { PATHS } from 'src/constants/paths';
export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={[PATHS.LOGIN]}>
          <Login />
        </Route>
        <Route
          exact
          path={[
            PATHS.HOME,
            PATHS.LEARN_SPACE,
            PATHS.LEARN_MINE,
            PATHS.PROJECT,
            PATHS.PROJECT_MINE,
            PATHS.RECRUIT,
            PATHS.EVENT,
            PATHS.MUSIC_SPACE,
            PATHS.MUSIC_MINE,
            PATHS.REPORT,
            PATHS.REPORT_MINE,
            PATHS.CONFIG_MARKETPLACES,
            PATHS.DEBT_LOGS,
            PATHS.PAYABLES_LOGS,
            PATHS.RECEIVABLES_LOGS,
            PATHS.SHOP_REVENUES,
            PATHS.DEIAL_SHOP_REVENUES,
            PATHS.APPROVAL_PRODUCTS,
            PATHS.REPORT_VIOLATIONS,
            PATHS.CALENDAR_CAMPAIGNS,
            PATHS.CAMPAIGNS,
            PATHS.MERCHANT_PAYMENT_REQUEST,
            PATHS.VOUCHERS,
            PATHS.PRODUCT,
            PATHS.PROMOTION,
            PATHS.NOTI_MKP,
            PATHS.POPUP_LANDING_PAGE,
            PATHS.BANNER_SHOP_MALL,
            PATHS.ORDER_FAILED,
            PATHS.REFUND_REQUESTS,
            PATHS.POST_CHART,
            PATHS.FEED,
            PATHS.TRENDING,

            PATHS.COMPLAIN,
            PATHS.COMPLAIN_USER,
            PATHS.COMPLAIN_PAGE,
            PATHS.COMPLAIN_GROUP,
            PATHS.COMPLAIN_EVENT,
            PATHS.COMPLAIN_GROW,
            PATHS.COMPLAIN_RECRUIT,
            PATHS.COMPLAIN_MUSIC,
            PATHS.COMPLAIN_COURSE,
            PATHS.COMPLAIN_PRODUCT,

            PATHS.COMPLAIN_REPORT,
            PATHS.BLOOD_DONNATIONS,
            PATHS.ENABLE_MONEY,
            PATHS.ENABLE_MONEY_USERS,
            PATHS.ENABLE_MONEY_PAGES,
            PATHS.REPORT_SYSTEM_ERROR,
            PATHS.VERIFY,
            PATHS.VERIFY_SUBJECT,
            PATHS.ADVERTISING_ACCOUNT,
            PATHS.FINANCIAL_CONTROL,
            PATHS.APPROVAL_BRAND,
            PATHS.HUMAN_RESOURCE,
            PATHS.FORBIDDEN
          ]}
        >
          <MainLayout>
            <MainRoutes />
          </MainLayout>
        </Route>
        <Route path={[PATHS.TEST]}>
          <Test />
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
